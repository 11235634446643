<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">设备类型</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
                <el-input v-model="name" placeholder="请输入名称" class="iw" ></el-input>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
               
                
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="IMG" label="图标">
                        <template slot-scope="scope">
                            <el-image style="width: 30px; height: 30px" :src="scope.row.IMG" fit="fill"></el-image>  
                        </template>
                    </el-table-column>
                    <el-table-column prop="Name" label="名称"></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                        <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                        <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="1000px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="300px" class="demo-uForm">
                <el-form-item label="名称" prop="Name">
                    <el-input v-model="postInfo.Name" autocomplete="off"></el-input>
                </el-form-item>
               
                <el-form-item label="图标" prop="ICONA">
                    <el-upload
                        class="avatar-uploader"
                        action="https://jsonplaceholder.typicode.com/posts/"
                        :show-file-list="false"
                        :http-request="uploadFunA"
                        :before-upload="beforeAvatarUploadA">
                        <img v-if="imageUrlA" :src="imageUrlA" class="avatar">
                        <el-button v-else type="primary" size="mini">添加图标</el-button>
                    </el-upload>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitPostForm()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
  name: "CT",
  data() {
    var Name = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("名称不能为空"));
      } else {
        callback();
      }
    };
    var ICONA = (rule, value, callback) => {
        if (value == 0) {
            callback(new Error("请选择图标"));
        } else {
            callback();
        }
      };
     

    
    return {
        canDo:true,
        showPagination:false,
        name:"",
       

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            Name: [{ validator: Name, trigger: "blur" }],
            ICONA: [{ validator: ICONA, trigger: "blur" }],
        },
        doData:"",
        imageUrlA:"",
        fileItemA:null,
       
    };
  },
  mounted() {
    var getQueryData=this.getQueryData(this.$route.name)
    if(getQueryData!= undefined){
        this.PageIndex= getQueryData.PageIndex
        this.name= getQueryData.name
    }
    this.showPagination=true
    this.loadItem=null
    this.getPostList()
  },
  methods: {
    getPostList(){
        this.showLoading("请稍后")
        var params={
            PageIndex:this.PageIndex,
            PageSize:this.PageSize,
            name:this.name,
        }
        this.setQueryData(this.$route.name,params)
        this.$http.post("DeviceType/GetDataList", params)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                var list=response.data.DATA
                var nl=[]
                for(var i=0;i<list.length;i++){
                    var d=list[i]
                    d.IMG=""
                    if(d.Files.length>0){
                        d.IMG=d.Files[0].Path
                    }
                    nl.push(d)
                }
                this.postList=nl
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            Name:"",
            ICONA:0,
          
        }
        this.imageUrlA=""
        this.fileItemA=null

        this.doTypeStr="添加设备类型"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("DeviceType/InsertData", {Name:this.postInfo.Name})
            .then((response) => {
                this.canDo=true
                if (response.data.SUCCESS) {
                    this.doData=response.data.DATA
                    this.addImgA()
                    this.$message.success("已添加")
                } else {
                    this.hideLoading()
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editPost(item){
        var imgUrl=""
        var icon=0
        if(item.Files.length>0){
            imgUrl = item.Files[0].Path
            icon=1
        }
        this.postInfo={
            snowID:item.SnowID,
            Name:item.Name,
            ICONA:icon,
        }
        this.imageUrlA=imgUrl
        this.fileItemA=null


        this.doTypeStr="修改设备类型"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var params={
            snowID:this.postInfo.snowID,
            name:this.postInfo.Name,
        }
        this.$http.post("DeviceType/UpdateData", params)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.doData=this.postInfo.snowID
                    if(this.fileItemA){
                        this.addImgA()
                    }else{
                        this.postModal=false
                        this.getPostList()
                    }
                    
                    this.$message.success("已修改")
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除类型：'+item.Name+'？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDel(item.SnowID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("DeviceType/DeleteData", {SnowID:id})
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    delete this.postInfo.ICONA
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    beforeAvatarUploadA(file) {
        const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG) {
        this.$message.error('图片只能是 JPG/PNG  格式!');
        }else if (!isLt2M) {
        this.$message.error('图片大小不能超过 2MB!');
        }else{
            var _URL = window.URL || window.webkitURL;
            this.imageUrlA = _URL.createObjectURL(file);
            this.postInfo.ICONA=1
            this.fileItemA=file
        }
        return isJPG && isLt2M;
    },
    uploadFunA(file){
        //console.log(file)
    },


    addImgA(){
        var fd = new FormData();
        fd.append("file", this.fileItemA);
        this.$upload.post("File/UpLoadFile?SourceSnowID="+this.doData+"&FileType=3&DeleteOld=1&Width=30&Hight=30", fd)
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.postModal=false
                    this.getPostList()
                }else{
                    this.hideLoading()
                    this.$message.error("上传失败")
                }
            })
            .catch((error) => {
            this.hideLoading()
        });
    },

  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>