<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">机组管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-select v-model="cq" placeholder="选择厂区"  filterable  clearable class="iw">
                        <el-option v-for="item in cqList" :key="item.SnowID" :label="item.Name" :value="item.SnowID" ></el-option>
                </el-select>
                <el-input v-model="name" placeholder="请输入名称" class="iw" ></el-input>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="FactoryName" label="厂区"></el-table-column>
                    <el-table-column prop="NAME" label="名称"></el-table-column>
                    <el-table-column prop="DESCRIPTION" label="描述"></el-table-column>
                    <el-table-column label="操作" width="200">
                        <template slot-scope="scope">
                            <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                            <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="pageIndex" :page-size="pageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="1200px" :close-on-click-modal="false">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="厂区" prop="factoryAreaSnowID">
                    <el-select v-model="postInfo.factoryAreaSnowID" placeholder="请选择厂区"  filterable  style="width:100%;">
                        <el-option v-for="item in cqList" :key="item.SnowID" :label="item.Name" :value="item.SnowID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="名称" prop="name">
                    <el-input v-model="postInfo.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="描述" prop="description">
                    <el-input v-model="postInfo.description" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>  
                    <el-button type="primary" @click="submitPostForm()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
  name: "UnitManage",
  data() {
    var name = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入名称"));
      } else {
        callback();
      }
    };
    var factoryAreaSnowID=(rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请选择厂区"));
      } else {
        callback();
      }
    };
    
    
    return {
        
        canDo:true,
        showPagination:false,
        name:"",
        cq:"",
        cqList:[],

        postList:[],
        pageIndex:1,
        pageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            name: [{ validator: name, trigger: "blur" }],
            factoryAreaSnowID: [{ validator: factoryAreaSnowID, trigger: "blur" }],
        },

    };
  },
  mounted() {
    var getQueryData=this.getQueryData(this.$route.name)
    if(getQueryData!= undefined){
        this.pageIndex= getQueryData.pageIndex
        this.name=getQueryData.name
        this.cq=getQueryData.factoryAreaSnowID
    }
    this.loadItem=null
    this.getCqList()
    this.getPostList()
  },
  methods: {
    getCqList(){
        this.$http.post("FactoryArea/GetDataList", {})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.cqList=response.data.DATA
                } 
            })
    },
    getPostList(){
        this.showLoading("请稍后")
        var params={
            pageIndex:this.pageIndex,
            pageSize:this.pageSize,
            name:this.name,
            factoryAreaSnowID:this.cq,
        }
        this.setQueryData(this.$route.name,params)
        this.$http.post("Set/GetDataList", params)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.$message.error(response.data.MSG)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
            });
    },
    searchPost(){
        this.pageIndex=1
        this.getPostList()
    },
    PGChange(e){
        this.pageIndex=e
        this.getPostList()
    },

    addPost(){
        this.postInfo={
            factoryAreaSnowID:"",
            name:"",
            description:"",
        }
        this.doTypeStr="添加机组"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("Set/InsertData", this.postInfo)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.getPostList()
                    this.postModal=false
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true
             });
    },
    editPost(item){
        this.postInfo={
            snowID:item.SnowID,
            factoryAreaSnowID:item.FACTORYAREASNOWID,
            name:item.NAME,
            description:item.DESCRIPTION,
        }
        this.doTypeStr="修改机组"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("Set/UpdateData", this.postInfo)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true
            });
    },
    submitPostForm(){
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除机组：'+item.NAME+'？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.sendDel(item.SnowID)
            }).catch(() => {
                this.$message.info("已取消")    
            });
        }
    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("Set/DeleteData", {snowid:id})
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
            });
    },

  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>

</style>