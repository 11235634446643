import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'

import AdminUserManage from '../views/pages/AdminUserManage.vue'
import SiteManage from '../views/pages/SiteManage.vue'
import AircrewManage from '../views/pages/AircrewManage.vue'
import FloorManage from '../views/pages/FloorManage.vue'
import MechineType from '../views/pages/MechineType.vue'
import MechineList from '../views/pages/MechineList.vue'


import store from '../store/index'
import cookies from 'vue-cookies'
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/Home',
    name: 'Home',
    component: Home,
    children: [
      {
        path: '/AdminUserManage',
        name: 'AdminUserManage',
        components: {AdminUserManage}
      },
      {
        path: '/SiteManage',
        name: 'SiteManage',
        components: {SiteManage}
      },
      {
        path: '/AircrewManage',
        name: 'AircrewManage',
        components: {AircrewManage}
      },
      {
        path: '/FloorManage',
        name: 'FloorManage',
        components: {FloorManage}
      },
      {
        path: '/MechineType',
        name: 'MechineType',
        components: {MechineType}
      },
      {
        path: '/MechineList',
        name: 'MechineList',
        components: {MechineList}
      },
      
    ]
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  //console.log("执行路由")
  var initRouter= store.state.initRouter
  if(initRouter == ""){
    if(to.name!= "Login"){
      store.commit('setOpenPage', to.name);
    }
    store.commit('setInitRouter', "1");
    router.replace("/")
  }else{
    next(true);
  }
});

router.afterEach((to) => {
  //////console.log(to)
});
export default router
