<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">设备管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-select v-model="cq" placeholder="选择厂区"  filterable  clearable class="iw" @change="changeCQS">
                        <el-option v-for="item in cqList" :key="item.SnowID" :label="item.Name" :value="item.SnowID" ></el-option>
                </el-select>
                <el-select v-model="jz" placeholder="选择机组"  filterable  clearable class="iw" @change="changeJZS">
                        <el-option v-for="item in jzListS" :key="item.SnowID" :label="item.NAME" :value="item.SnowID" ></el-option>
                </el-select>
                <el-select v-model="lc" placeholder="选择楼层"  filterable  clearable class="iw">
                        <el-option v-for="item in lcListS" :key="item.SnowID" :label="item.NAME" :value="item.SnowID" ></el-option>
                </el-select>
                <el-select v-model="lx" placeholder="选择类型"  filterable  clearable class="iw" >
                        <el-option v-for="item in lxList" :key="item.SnowID" :label="item.Name" :value="item.SnowID" ></el-option>
                </el-select>
                <el-input v-model="name" placeholder="请输入名称" class="iw" ></el-input>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="NAME" label="名称"></el-table-column>
                    <el-table-column prop="DEVICETYPENAME" label="类型"></el-table-column>
                    <el-table-column prop="FLOORNAME" label="楼层"></el-table-column>
                    <el-table-column prop="DESCRIPTION" label="描述"></el-table-column>
                    <el-table-column prop="BZ1" label="备注1"></el-table-column>
                    <el-table-column prop="BZ2" label="备注2"></el-table-column>
                    <el-table-column label="操作" width="200">
                        <template slot-scope="scope">
                            <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                            <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="pageIndex" :page-size="pageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="1200px" :close-on-click-modal="false">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="名称" prop="name">
                    <el-input v-model="postInfo.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="楼层" prop="floorsnowid">
                    <el-select v-model="cqF" placeholder="请选择厂区"  filterable  style="width:32%;" @change="changeCQF">
                        <el-option v-for="item in cqList" :key="item.SnowID" :label="item.Name" :value="item.SnowID" ></el-option>
                    </el-select>
                    <el-select v-model="jzF" placeholder="请选择机组"  filterable  style="width:32%;margin-left:2%" @change="changeJZF">
                        <el-option v-for="item in jzListF" :key="item.SnowID" :label="item.NAME" :value="item.SnowID" ></el-option>
                    </el-select>
                    <el-select v-model="postInfo.floorsnowid" placeholder="请选择楼层"  filterable  style="width:32%;margin-left:2%">
                        <el-option v-for="item in lcListF" :key="item.SnowID" :label="item.NAME" :value="item.SnowID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="类型" prop="devicetypesnowid">
                    <el-select v-model="postInfo.devicetypesnowid" placeholder="请选择类型"  filterable  style="width:100%;">
                        <el-option v-for="item in lxList" :key="item.SnowID" :label="item.Name" :value="item.SnowID" ></el-option>
                    </el-select>
                </el-form-item>
                
                <el-form-item label="描述">
                    <el-input v-model="postInfo.description" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="备注1">
                    <el-input v-model="postInfo.bZ1" autocomplete="off" placeholder="监控设备填播放地址，其他设备填信息查询地址，可不填"></el-input>
                </el-form-item>
                <el-form-item label="备注2">
                    <el-input v-model="postInfo.bZ2" autocomplete="off" placeholder="监控设备填回放地址，其他设备填维护记录查询地址，可不填"></el-input>
                </el-form-item>
                <el-form-item>  
                    <el-button type="primary" @click="submitPostForm()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
  name: "UnitManage",
  data() {
    var name = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入名称"));
      } else {
        callback();
      }
    };
    var floorsnowid=(rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请选择楼层"));
      } else {
        callback();
      }
    };
    
    var devicetypesnowid=(rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请选择类型"));
      } else {
        callback();
      }
    };
    
    return {
        
        canDo:true,
        showPagination:false,
        lx:"",
        lxList:[],
        name:"",
        cq:"",
        cqList:[],
        jz:"",
        jzListS:[],
        lc:"",
        lcListS:[],


        cqF:"",
        jzF:"",
        jzListF:[],
        lcListF:[],
        postList:[],
        pageIndex:1,
        pageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            name: [{ validator: name, trigger: "blur" }],
            floorsnowid: [{ validator: floorsnowid, trigger: "blur" }],
            devicetypesnowid: [{ validator: devicetypesnowid, trigger: "blur" }],
        },

    };
  },
  mounted() {
    var getQueryData=this.getQueryData(this.$route.name)
    if(getQueryData!= undefined){
        this.pageIndex= getQueryData.pageIndex
        this.name=getQueryData.name
        this.lc=getQueryData.floorSnowID
        this.lx=getQueryData.deviceTypeSnowID
    }
    this.loadItem=null
    this.getCqList()
    this.getLXList()
    this.getPostList()
  },
  methods: {

    getPostList(){
        this.showLoading("请稍后")
        var params={
            pageIndex:this.pageIndex,
            pageSize:this.pageSize,
            name:this.name,
            floorSnowID:this.lc,
            deviceTypeSnowID:this.lx,
        }
        this.setQueryData(this.$route.name,params)
        this.$http.post("Device/GetDataList", params)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.$message.error(response.data.MSG)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
            });
    },
    searchPost(){
        this.pageIndex=1
        this.getPostList()
    },
    PGChange(e){
        this.pageIndex=e
        this.getPostList()
    },

    addPost(){
        this.postInfo={
            floorsnowid:"",
            name:"",
            description:"",
            devicetypesnowid:"",
            bZ1:"",
            bZ2:"",
        }
        this.doTypeStr="添加楼层"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("Device/InsertData", this.postInfo)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.getPostList()
                    this.postModal=false
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true
             });
    },
    editPost(item){
        this.jzListF=[]
        this.jzF=""
        this.lcListF=[]
        this.cqF=""
        this.setLcSelect(item.FLOORSNOWID)
        this.postInfo={
            snowID:item.SnowID,
            floorsnowid:item.FLOORSNOWID,
            name:item.NAME,
            description:item.DESCRIPTION,
            devicetypesnowid:item.DEVICETYPESNOWID,
            bZ1:item.BZ1,
            bZ2:item.BZ2,
        }
        this.doTypeStr="修改楼层"
        this.doType="2"
        this.postModal=true
    },
    //通过楼层ID找到所在机组
    setLcSelect(lcid){
        this.$http.post("Floor/GetDataList", {snowID:lcid})
            .then((response) => {
                if (response.data.SUCCESS) {
                    if(response.data.DATA.length>0){
                        var d=response.data.DATA[0]
                        this.getLcFList(d.SETSNOWID)
                    }
                } 
            })
    },
    //通过机组ID获取楼层列表，以及获取机组所在厂区
    getLcFList(jzid){
        this.jzF=jzid
        this.$http.post("Floor/GetDataList", {setSnowID:jzid})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.lcListF=response.data.DATA
                } 
            })
        this.$http.post("Set/GetDataList", {snowID:jzid})
        .then((response) => {
            if (response.data.SUCCESS) {
                if(response.data.DATA.length>0){
                    var d=response.data.DATA[0]
                    this.cqF=d.FACTORYAREASNOWID
                    this.getJZList(d.FACTORYAREASNOWID)
                }
            } 
        })
    },
    //通过厂区ID获取机组列表
    getJZList(cqid){
        this.$http.post("Set/GetDataList", {factoryAreaSnowID:cqid})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.jzListF=response.data.DATA
                } 
            })
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("Device/UpdateData", this.postInfo)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true
            });
    },
    submitPostForm(){
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除设备：'+item.NAME+'？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.sendDel(item.SnowID)
            }).catch(() => {
                this.$message.info("已取消")    
            });
        }
    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("Device/DeleteData", {SnowID:id})
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
            });
    },
    getCqList(){
        this.$http.post("FactoryArea/GetDataList", {})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.cqList=response.data.DATA
                } 
            })
    },
    changeCQS(e){
        this.jz=""
        this.jzListS=[]
        this.lc=""
        this.lcListS=[]
        if(e!=""){
            this.getJZS()
        }
    },
    getJZS(id){
        this.$http.post("Set/GetDataList", {})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.jzListS=response.data.DATA
                } 
            })
    },
    changeJZS(e){
        this.lc=""
        this.lcListS=[]
        if(e!=""){
            this.getLCS(e)
        }
    },
    getLCS(id){
        this.$http.post("Floor/GetDataList", {setSnowID:id})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.lcListS=response.data.DATA
                } 
            })
    },
    changeCQF(e){
        this.jzF=""
        this.jzListF=[]
        this.postInfo.floorsnowid=""
        this.lcListF=[]
        if(e!=""){
            this.getJZF()
        }
    },
    getJZF(id){
        this.$http.post("Set/GetDataList", {})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.jzListF=response.data.DATA
                } 
            })
    },
    changeJZF(e){
        this.postInfo.floorsnowid=""
        this.lcListF=[]
        if(e!=""){
            this.getLCF(e)
        }
    },
    getLCF(id){
        this.$http.post("Floor/GetDataList", {setSnowID:id})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.lcListF=response.data.DATA
                } 
            })
    },
    getLXList(){
        this.$http.post("DeviceType/GetDataList", {})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.lxList=response.data.DATA
                } 
            })
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>

</style>